.historia-container {
    padding: 20px;
    background-color: #f1f1f1;
    margin: 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
}

.banner-logo-empresa {
    background-image: url('../img/gallery/1.JPG');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.banner-logo-empresa img {
    margin: auto;
}

.historia-title {
    color: #08046C;
    font-size: 24px;
    margin-bottom: 10px;
}

.historia-text {
    color: #333;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}