.banner {
    position: relative;
    width: 100%;
    height: 550px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.banner-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: 0.7;
}

.banner-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
    padding: 20px;
    /* Add padding to ensure content isn't too close to edges */
}

.banner-content a {
    text-decoration: none;
    color: #fff;
}

.banner-logo {
    width: 300px;
    margin-bottom: 10px;
}

.banner-text {
    font-size: 30px;
    margin-bottom: 20px;
    text-shadow: -2px -2px 0 #08046C, 2px -2px 0 #08046C, -2px 2px 0 #08046C, 2px 2px 0 #08046C;
}

.banner-button {
    padding: 10px 20px;
    font-size: 20px;
    color: #fff;
    background-color: #08046C;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.banner-button:hover {
    background-color: #050334;
}

@media (max-width: 768px) {
    .banner {
        height: 400px;
        /* Adjust height for smaller screens */
    }

    .banner-logo {
        width: 200px;
        /* Adjust logo size for smaller screens */
    }

    .banner-text {
        font-size: 24px;
        /* Adjust font size for smaller screens */
        margin-bottom: 15px;
    }

    .banner-button {
        padding: 8px 16px;
        /* Adjust button padding for smaller screens */
        font-size: 18px;
        /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .banner {
        height: 350px;
        /* Adjust height for smaller screens */
    }

    .banner-logo {
        width: 150px;
        /* Adjust logo size for smaller screens */
    }

    .banner-text {
        font-size: 20px;
        /* Adjust font size for smaller screens */
        margin-bottom: 10px;
    }

    .banner-button {
        padding: 6px 12px;
        /* Adjust button padding for smaller screens */
        font-size: 16px;
        /* Adjust font size for smaller screens */
    }
}