.footer {
    background-color: #D9D9D9;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Align items vertically in the center */
}

.footer-left,
.footer-right {
    width: 50%;
}

.footer-left p,
.footer-right p {
    font-size: 20px;
    color: #08046C;
}

.footer-right p {
    text-align: right;
}

.footer a {
    text-decoration: none;
    color: #08046C;
}

.footer a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        /* Center align items in the column */
        text-align: center;
    }

    .footer-left,
    .footer-right {
        width: 100%;
    }

    .footer-right p {
        text-align: center;
    }

    .footer-left p,
    .footer-right p {
        font-size: 18px;
    }
}

@media (max-width: 480px) {

    .footer-left p,
    .footer-right p {
        font-size: 16px;
    }
}