.before-after-gallery-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.before-after-gallery-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.before-after-gallery-item {
    position: relative;
    flex: 1 1 calc(33.33% - 20px);
    cursor: grab;
}

.before-after-gallery-image {
    width: 100%;
    transition: transform 0.3s ease-in-out;
    height: 250px;
}

.dragging {
    cursor: grabbing;
}

.arrow-left,
.arrow-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
}

.arrow-left {
    left: 10px;
}

.arrow-right {
    right: 10px;
}

.before-after-caption {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
}

@media (max-width: 768px) {
    .before-after-gallery-item {
        flex: 1 1 calc(100% - 20px);
    }

    .arrow-left,
    .arrow-right {
        font-size: 20px;
        padding: 8px;
    }

    .before-after-caption {
        font-size: 14px;
    }
}