.location-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: #ffffff;
}

.location-info {
    flex: 1;
    max-width: 45%;
    padding: 20px;
    color: #1c2a6d;
}

.contacts,
.address {
    margin-bottom: 20px;
}

.contacts h2,
.address h2 {
    margin-bottom: 10px;
}

.contacts p,
.address p {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.contacts img,
.address img {
    margin-right: 10px;
}

.map {
    flex: 1;
    max-width: 80%;
    padding: 20px;
}

.map iframe {
    width: 100%;
    border: 0;
    border-radius: 5px;
}


@media (max-width: 768px) {
    .location-container {
        flex-direction: column;
        align-items: center;
    }

    .location-info,
    .map {
        max-width: 100%;
    }

    .contacts,
    .address {
        margin-bottom: 15px;
    }
}