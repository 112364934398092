.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0b1752;
    color: white;
    padding: 20px;
}

.contact-form {
    width: 40%;
    display: flex;
    flex-direction: column;
    background-color: #0b1752;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2,
.contact-form h1 {
    margin-bottom: 20px;
    text-align: center;
}

.contact-form label {
    display: block;
    margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #fff;
    color: #0b1752;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    font-size: 20px;
    margin-left: 40%;
}

.contact-form button:hover {
    background-color: #eee;
}

.contact-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-image {
    width: 50%;
}

.contact-image img {
    width: 100%;
    border-radius: 10px;
}

.contact-whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}

.contact-whatsapp a {
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 25px;
    padding: 10px;
}

.contact-whatsapp a:hover {
    color: #0b1752;
    display: flex;
    align-items: center;
    font-size: 25px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.contact-whatsapp img {
    height: 50px;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form {
        width: 90%;
        margin-bottom: 20px;
    }

    .contact-image {
        width: 90%;
    }

    .contact-form button {
        width: 100%;
        margin-left: 10px;
    }

    .contact-whatsapp a {
        font-size: 20px;
    }
}