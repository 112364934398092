.why-choose-us {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.why-choose-us h2 {
    font-size: 2.5em;
    color: #08046C;
    margin-bottom: 20px;
}

.why-choose-us p {
    font-size: 1.2em;
    color: #5a5a5a;
    margin-bottom: 40px;
    border-bottom: 3px solid #08046C;
    display: inline-block;
    padding-bottom: 10px;
}

.why-choose-us-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.why-choose-us-card {
    background-color: #fff;
    border: 2px solid #08046C;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.why-choose-us-card img {
    height: 100px;
    margin-bottom: 20px;
}

.why-choose-us-card h3 {
    font-size: 1.5em;
    color: #08046C;
    margin-bottom: 10px;
}

.why-choose-us-card p {
    font-size: 1em;
    color: #5a5a5a;
}

.why-choose-us-card:hover {
    transform: translateY(-10px);
}

@media (max-width: 1024px) {
    .why-choose-us-card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .why-choose-us h2 {
        font-size: 2em;
    }

    .why-choose-us p {
        font-size: 1em;
    }

    .why-choose-us-card {
        width: 90%;
        margin-bottom: 20px;
    }

    .why-choose-us-card h3 {
        font-size: 1.2em;
    }

    .why-choose-us-card p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .why-choose-us h2 {
        font-size: 1.5em;
    }

    .why-choose-us p {
        font-size: 0.9em;
    }

    .why-choose-us-card img {
        height: 80px;
    }

    .why-choose-us-card h3 {
        font-size: 1em;
    }

    .why-choose-us-card p {
        font-size: 0.8em;
    }
}