.navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: #D9D9D9;
    max-height: 95px;
    width: 100%;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
}

.navbar-logo img {
    height: 90px;
    padding: 10px 10px;
}

.nav-right {
    display: flex;
    align-items: center;
    transition: max-height 0.3s ease-in-out;
}

.navbar-links {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    font-size: 15px;
}

.navbar-link {
    text-decoration: none;
    color: #08046C;
    padding: 10px 20px;
    font-weight: bold;
}

.navbar-link:hover {
    background-color: #08046C;
    color: #fff;
    border-radius: 25px;
}

.navbar-link.active {
    background-color: #08046C;
    color: #fff;
    text-decoration: underline;
    border-radius: 25px;
}

.navbar-whatsapp img {
    height: 50px;
    margin-right: 30px;
}

.navbar-whatsapp img:hover {
    transform: scale(1.1);
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 4px 30px;
    transition: 0.4s;
}

@media (max-width: 768px) {
    .nav-right {
        position: absolute;
        top: 95px;
        left: 0;
        right: 0;
        background-color: #D9D9D9;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
    }

    .nav-right.open {
        max-height: 500px;
        /* Ajuste conforme necessário */
    }

    .navbar-links {
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .navbar-links li {
        margin-top: 30px;
    }

    .navbar-link {
        padding: 15px;
        text-align: center;
        width: 100%;
    }

    .navbar-whatsapp {
        padding: 15px;
        text-align: center;
        width: 100%;
        margin-left: 30px;
    }

    .menu-toggle {
        display: flex;
    }
}

.main-content {
    padding-top: 95px;
}