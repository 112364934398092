.about-us-parallax {
    background-image: url('../img/bgqs.JPG');
    height: 200px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-title {
    color: white;
    font-size: 3em;
    text-shadow: -2px -2px 0 #08046C, 2px -2px 0 #08046C, -2px 2px 0 #08046C, 2px 2px 0 #08046C;
}

.about-us-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px;
    background-color: #f1f1f1;
}

.about-us-image img {
    border-radius: 15px;
    max-width: 800px;
}

.about-us-text {
    max-width: 600px;
    text-align: justify;
    border: 2px solid #08046C;
    border-radius: 15px;
    padding: 20px;
    background-color: #e0e0e0;
    margin: 20px;
}

.about-us-video {
    margin: 40px auto;
    width: 80%;
    text-align: center;
}

.about-us-video iframe {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .about-us-title {
        font-size: 2em;
        /* Adjust title font size for smaller screens */
    }

    .about-us-content {
        padding: 20px;
        /* Adjust padding for smaller screens */
        flex-direction: column;
        /* Stack content vertically on smaller screens */
    }

    .about-us-image img {
        width: 100%;
    }

    .about-us-text {
        margin: 20px 0;
        /* Adjust margin for smaller screens */
    }
}