.tecnologia-container {
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    width: 93%;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner-logo-tec {
    background-image: url('../img/tecnologiabg.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.tecnologia-title {
    font-size: 24px;
    margin-bottom: 16px;
    color: #08046C;
}

.tecnologia-text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
    text-align: justify;
}

.video-container {
    margin: 20px 0;
    text-align: center;
}

.video-container iframe {
    max-width: 100%;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .tecnologia-container {
        width: 90%;
        padding: 15px;
    }

    .tecnologia-title {
        font-size: 20px;
    }

    .tecnologia-text {
        font-size: 14px;
    }

    .banner-logo-tec {
        height: 300px;
    }

    .video-container iframe {
        height: 350px;
    }
}