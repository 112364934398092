.galeria-container {
    padding: 20px;
    background-color: #f1f1f1;
    margin: 20px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
}

.galeria-title {
    color: #08046C;
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.galeria-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.galeria-item {
    flex: 1 1 calc(25% - 10px);
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    max-width: calc(25% - 10px);
    max-height: 250px;
}

.galeria-image {
    width: 100%;
    height: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-image {
    max-width: 90%;
    max-height: 90%;
    border: 2px solid white;
    border-radius: 10px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background: #333;
    border: none;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1001;
}

@media (max-width: 768px) {
    .galeria-item {
        flex: 1 1 calc(50% - 20px);
        max-width: 50%;
    }

    .galeria-title {
        font-size: 20px;
    }
}